<template>
  <modal @resetModal="resetForm" v-loading="loaders.modal"
         :show.sync="$store.state['BariResourceModule'].modal.addEditBariResourceCategoryModal">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">{{ modalTitle }}</h1>
    <validation-observer ref="addBariResourceCategoryForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input
          type="text"
          :rules="{required: true}"
          v-model="request.bariResourceCategory.name"
          name="name"
          label="Name*"
          placeholder="Name">
        </base-input>
        <base-input name="status" label="Status*" :rules="{required: true}">
          <el-select
            class="w-100"
            label="Status*"
            v-model="request.bariResourceCategory.status">
            <el-option
              v-for="option in dropdowns.status"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>

        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="resetForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            {{ actionButtonText }}
          </base-button>
        </div>

      </form>
    </validation-observer>
  </modal>
</template>

<script>
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "AddEditBariResourceCategory",
  props: {
    categoryId: {
      type: Number,
      default: 0,
      description: "Bari Resource Category ID to edit"
    },
    modalTitle: {
      type: String,
      default: 'Add Bari Resource Category',
      description: "Bari Resource Category Modal Title to be shown"
    },
    actionButtonText: {
      type: String,
      default: 'Save',
      description: "Bari Resource Category Modal Action Button Text"
    }
  },
  data() {
    return {
      loaders: {
        modal: false
      },
      request: {
        bariResourceCategory: {...this.$store.getters['BariResourceModule/getBariResourceCategory']}
      },
      dropdowns: {
        status: [
          {
            value: 1, label: 'Active'
          },
          {
            value: 0, label: 'Inactive'
          },
        ],
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  watch: {
    categoryId(newVal, oldVal) {
      if (newVal != 0 && newVal != oldVal) {
        this.getBariResourceCategory()
      } else {
        delete this.request.bariResourceCategory["id"]
      }
    }
  },
  methods: {
    /**
     * Get Bari Resource Category
     */
    getBariResourceCategory() {
      let vm = this
      vm.loaders.modal = true
      const payload = {
        id: vm.categoryId ?? 0
      }
      vm.$store.dispatch('BariResourceModule/_getRequestedBariResourceCategory', payload)
        .then(response => {
          vm.request.bariResourceCategory = response.data.data
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Bari Resource Category',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.modal = false
        })
    },

    /**
     * Handle cancel form event
     */
    resetForm() {
      this.$refs.addBariResourceCategoryForm.reset()
      this.request.bariResourceCategory = {...this.$store.getters['BariResourceModule/getBariResourceCategory']}
      this.$emit('clearEditId')
      this.$store.commit('BariResourceModule/toggleAddEditBariResourceCategoryModal', false)
    },

    /**
     * Submit form action
     */
    submitForm() {
      let vm = this
      if (vm.categoryId > 0) {
        vm.editBariResourceCategory()
      } else {
        vm.addBariResourceCategory()
      }
    },

    /**
     * Add Bari Resource Category
     */
    addBariResourceCategory() {
      let vm = this
      vm.loaders.modal = true
      const payload = {...this.request.bariResourceCategory}
      vm.$store.dispatch('BariResourceModule/_storeBariResourceCategory', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Bari Resource Category Added Successfully!'
          })
          this.resetForm()
          this.$emit('refreshBariResourceCategories')
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Add Bari Resource Category',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.modal = false
        })
    },

    /**
     * Edit Bari Resource Category
     */
    editBariResourceCategory() {
      let vm = this
      vm.loaders.modal = true
      const payload = {...this.request.bariResourceCategory}
      vm.$store.dispatch('BariResourceModule/_updateBariResourceCategory', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Bari Resource Category Edited Successfully!'
          })
          this.resetForm()
          this.$emit('refreshBariResourceCategories')
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Edit Bari Resource Category',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.modal = false
        })
    }
  }
}
</script>

<style scoped>

</style>
